import React from "react";
import Intro from "../components/intro";

export default function Home() {
  return (
    <div>
      <Intro />
    </div>
  );
}
